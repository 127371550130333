body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-item.disabled .page-link {
  border-color: aliceblue;
}

.prod-table {
  max-width: 100%;
  overflow: auto;
}

h6.mb-0.text-nowrap.prod-title {
  max-width: 215px;
  white-space: break-spaces !important;
  min-width: 96px;
  text-align: center;
  line-height: 1.5;
}
.prod-table td {
  padding: 5px !important;
}

/* medea queries */
.filter-check-controller {
  display: flex;
  align-items: center;
}

.zc-invoice-list-controller-search {
  justify-content: start;
  padding-left: 24px;
  position: relative;
}

@media (max-width:1710px){
  .zc-invoice-list-controller {
    gap: 0 !important;
    width: 100%;
    padding-top: 25px;
  }
}

@media (max-width:1310px){
  .zc-invoice-list-controller {
     position: relative;
  }

  .zc-special-controller {
      position: absolute;
      right: 0;
      bottom: 60px;
  }

}

@media (max-width: 989px){
  .zc-special-controller {
    position: unset;
    width: 100%;
    padding-top: 15px;
}
}