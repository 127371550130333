.vh-100 {
  height: 100vh;
}

.page-item {
  cursor: pointer;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  /* width: 80px; */
  /* height: 80px; */
  bottom: 0;
  left: auto;
  right: auto;
  top: -42px;
}

.company-loader .lds-ellipsis {
  top: 0;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2c7be5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: center;
  top: 0;
  left: 0;
  background-color: #fbfbfb;
  display: grid;
  place-items: center;
}

.loader {
  border: 16px solid #3636ee;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.round {
  margin-bottom: 11px;
  margin-top: 7px;
  background-color: #edebf7;
  color: black;
  border-radius: 100%;
  width: 42px;
  height: 41px;
  font-size: larger;
}


.comments {
  box-shadow: var(--falcon-box-shadow-inset);
  color: var(--falcon-input-color);
  background-color: var(--falcon-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--falcon-input-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  display: block;
  /* width: 1122px !important; */
  height: 117px;
  padding: 10px;
  background-color: #f9f9f9;
  color: #3e3e3e;
}


.mt-3 {
  margin-top: 2rem !important;
}




.btn:hover {
  background: #eef;
}

.zoom {
  bottom: 190px;
}

.zoom-out {
  bottom: 120px;
}

.zoom-init {
  bottom: 50px;
}

/* new design common */

.zeconcile-bg {
  background-color: #F1F5F9;
}

div#navbarVerticalCollapse {
  box-shadow: none !important;
}

div#navbarVerticalCollapse {
  box-shadow: none !important;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-link.active {
  background: #5945B3;
  border-radius: 8px;
  /* padding: 8px 15px; */
  padding-right: 0;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link div,
.navbar-light .navbar-nav .nav-link div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #94A3B8;
  text-transform: capitalize;
  padding: 8px 10px;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.active div,
.navbar-light .navbar-nav .nav-link.active div {
  color: #EDEAF7;
}

.navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content.zc-sidebar-content {
  padding: 0px !important;
  padding-right: 0 !important;
  width: 14.8rem !important;
  padding-left: 4px !important
}

.content.max-h-screen.zc-main-content {
  padding: 5px;
  /* margin-top: 14px; */
  background: #ffff;
  border-radius: 16px;
}

/* invoice details page  */
input {
  border-radius: 15px;
}

input.form-control.search {
  background-image: url('../public/assets/Search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 35px;
  /* adjust the value to make room for the icon */
}

.navbar-vertical-label p {
  color: #475569 !important;
}

.mgc {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background: #F1F5F9;
  border-radius: 12px;
  border: 0;
  /* Inside auto layout */

  /* flex: start;
  order: 8;
  align-self: stretch;
  flex-grow: 0;
  z-index: 8; */
}

.f-14 {
  font-size: 14px;
}

.dot-bg {
  min-height: 34px;
  padding: 8px;
  border: 1px;
  border-radius: 12px;
  background-color: #EDEAF7;
}

.b-radius {
  border-radius: 16px !important;
}

.mt-40 {
  margin-top: 40px;
}

.clr-purple {
  color: #5945B3 !important;
}

.bg-purple {
  background-color: #5945B3 !important;
}

.user {
  height: 76px;
  background: #5945B3;
  border-radius: 12px;
  color: white;

}

/* .user h5 {} */

.user-img img {
  /* position: relative; */
  border-radius: 50px;
  /* margin-top: 5px; */
  width: 40px;
  height: 40px;
}



#options-menu {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
}

#options-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ccc;
}

#options-menu ul li {
  padding: 10px;
  cursor: pointer;
}

#options-menu ul li:hover {
  background-color: #f5f5f5;
}

.trans {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
  color: #64748B !important;
}

.trans1 {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}

.debt-bg {
  background: #F1F5F9;
  padding: 4px !important;
}

.activ {
  align-self: center;
  padding: 8px;
  background-color: #fff !important;
}

.bg-chat-body {
  background: #F8FAFC;
}

.border-chat-msg {
  border-radius: 12px 12px 0px 12px !important;
}

.border-chat-msg1 {
  border-radius: 12px 12px 12px 0px !important;
}

.active h6.mb-0.chat-contact-title {
  color: #5945B3;
  font-weight: 700;
}

.chat-contact.active:after {
  background-color: transparent;
}

.navbar-vertical-collapsed input.form-control.search {
  width: 40px;
  padding-left: 20px;
}

/* .navbar-vertical-collapsed .recentclients {
  display: none;
} */
.f-w {
  font-weight: 700;
}

.navbar-vertical-collapsed .clients-head {
  margin-top: 0px !important;
}

.navbar-vertical-collapsed .clients,
.navbar-vertical-collapsed .navbar-collapse .nav-item {
  /* margin-top: 0px !important; */
  width: 3em !important;
}

.navbar-vertical-collapsed .mng-c {
  display: none !important;
}

/* .navbar-vertical-collapsed .user {
  display: none;
} */
@media (min-width: 1200px) {

  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted {
    width: 3.125rem !important;
  }
}

.navbar-vertical-collapsed .zec-logo {
  display: unset !important;
  /* width: 2.25em; */
  margin-left: -20px !important;
  /* margin-top: 1rem !important; */
  /* padding-bottom: 1rem !important; */
  /* margin-right: 0 !important; */
}


.zec-logo {
  display: none;
}

/*
.navbar-vertical .zec-logo .svg1 {
  display: none !important;
 } */
.navbar-vertical-collapsed .u-none {
  display: block;
}

.u-none {
  display: none;
}

.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .navbar-vertical-divider {
  max-width: 3rem;
}

li.dropdown-item:hover {
  background: #edeaf7;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card {
  width: 4.125rem;
}

.quick-test-form {
  max-width: 50%;
  display: block;
  margin: 0 auto;
}

.quick-test-form label.quickform-label {
  font-size: 16px !important;
  margin-top: 10px;
}

.table input {
  border: 0 !important;
  width: 150px;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary {
  list-style-type: none;
  cursor: pointer;
}

.no-dropdown {
  /* Removes the default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 600;

  /* Add a custom background and border to make it look like a regular block element */
  background-color: white;
  border: 1px solid #ccc;
  /* padding: 5px; */
  /* width: 200px; Adjust as needed */
  cursor: pointer;
}

/* Styling the dropdown indicator area for Firefox */
.no-dropdown::-ms-expand {
  display: none;
}

.remove_border {
  border-left: 0px !important;
  border-right: 0px !important;
}

.edit-btn {
  margin-top: -15px;
  /* margin-left: 890px; */
  margin-left: 5px;
  padding: 2px;
  gap: 5px;
  background-color: white;
  min-width: 120px;
}
.btn.edit-btn:hover{
  background: white;
}