.bg-login {
  background-color: #F1F5F9;
  background-size: contain;

  background-position: 50% 20%;
}

.bg-login1 {
  content: url(../../assets/img/Login\ Left\ Panel.png);
  padding: 5px;
  height: 100vh;
}

.card {
  z-index: 5;
  border-radius: 24px;
}

/* .card {
  padding: 15px;
} */


.login-form-bg {
  content: url(../../assets/img/Rectangle\ 885.png);
  position: fixed;
  z-index: 0;
  margin-top: -120px;
  margin-left: 350px;
  width: 268.71px;
  height: 268.71px;
  /* left: 648.63px; */
  /* top: 592.97px; */
}

.m-t {
  margin-top: -45px;
  right: 0;
  padding: 5px 9px 5px 8px;
  /* margin-left: 390px; */
}

/* {
  content: 
} */

.prpl {
  color: #5945B3;
  font-weight: 500;
}

.login-head:hover {
  text-decoration: none;
}