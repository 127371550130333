input,
option {
    border-radius: 12px !important;
}

.css-13cymwt-control {
    border-radius: 12px !important;
    /* width: 100%; */
}

.css-b62m3t-container {
    width: 100%;
}

.form-select {
    border-radius: 12px !important;
}

label {
    font-size: 13px !important;
}

.box {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;

    width: 48%;
    /* height: 212px; */

    /* Neutral / ZC_300

CBD5E1
*/
    border: 1px solid #CBD5E1;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.goods {
    background-color: #EDEAF7;
    color: #5945B3;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid #A398D5;
    padding: 12px;
}

/* .goods :hover{
    background-color: #5945B3;
} */
label {
    font-family: 'inter' !important;
    font-style: normal;
    font-weight: 500 !important;
    color: #475569;
}

/* .invoice-detail{
    width: 922px;
    border: 1px black;
} */
/*  */
/* .btn1{
    width: 88px !important;
} */
.justify-content-space-between {
    justify-content: space-between;
}

.text-area {
    min-width: 160px;
    border-radius: 12px;
}